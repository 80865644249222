import { useMutation } from '@apollo/client'
import { Menu, MenuItem } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ExportDialog } from '../../components/export-dialog'
import { MoreButton } from '../../components/more-button'

import {
  ExportInfluencerCodesDocument,
  FileType,
} from '../../generated/graphql'
import { useTracking } from '../../hooks/tracking'
import { useMe } from '../../hooks/me'

export const InfluencerCodesContextMenu = () => {
  const { t } = useTranslation(['shared', 'influencer_codes'])
  const { trackButtonClickEvent } = useTracking()
  const [isExportDialogOpen, setExportDialogOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const { me } = useMe()

  const handleClose = () => setAnchorEl(null)

  const [exportCodes, { loading }] = useMutation(ExportInfluencerCodesDocument)

  const onExport = async (fileType: FileType) => {
    try {
      const timeZone = me?.timeZone
      const { data } = await exportCodes({ variables: { fileType, timeZone } })

      const fileLink = data?.exportInfluencerCodes

      if (fileLink) {
        window.location.href = fileLink
      }

      enqueueSnackbar(t('influencer_codes:export_success'), {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar(t('influencer_codes:export_error'), { variant: 'error' })
    } finally {
      setExportDialogOpen(false)
    }
  }

  return (
    <>
      <MoreButton onClick={handleClick} />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        data-test-id="more-options-button"
        onClick={handleClose}
        onClose={handleClose}
      >
        <MenuItem
          data-testid="export-influencer-codes-option"
          onClick={
            (trackButtonClickEvent({
              name: 'export-influencer-codes',
            }),
            () => setExportDialogOpen(true))
          }
        >
          {t('influencer_codes:export_influencer_codes')}
        </MenuItem>
      </Menu>

      <ExportDialog
        open={isExportDialogOpen}
        onClose={() => setExportDialogOpen(false)}
        label={t('influencer_codes:export_influencer_codes')}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSuccess={onExport}
        isLoading={loading}
      />
    </>
  )
}
