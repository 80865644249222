import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { ChevronLeftIcon } from '@sitoo/mui-components'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { RootRoute } from '../..'
import { CampaignType } from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useTracking } from '../../../hooks/tracking'
import { CampaignTypeSelection } from './campaign-type-selection'
import { CartCampaignSelection } from './cart-campaign-selection'
import { PackageCampaignSelection } from './package-campagin-selection'
import { PricelistCampaignSelection } from './pricelist-campaign-selection'
import { ProductCampaignSelection } from './product-campaign-selection'

export type AddCampaignDialogProps = {
  open: boolean
  onClose(): void
}

export type CampaignTypeGroup =
  | 'cart'
  | 'product'
  | 'package'
  | 'pricelist'
  | undefined

export const AddCampaignDialog = (props: AddCampaignDialogProps) => {
  const { t } = useTranslation(['campaigns', 'shared'])
  const dialogName = 'add-campaign'
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const {
    trackButtonClick,
    trackButtonClickEvent,
    trackDialogOpen,
    trackDialogClose,
  } = useTracking()

  const [campaignTypeSelection, setCampaignTypeSelection] =
    useState<CampaignTypeGroup>()

  const redirectToAddCampaignPage = (campaignType: CampaignType) => {
    navigate({
      pathname: generatePath(RootRoute.CampaignNew),
      search: createSearchParams({ campaignType }).toString(),
    })
  }

  const onCampaignTypeGroupSelection = (selection: CampaignTypeGroup) => {
    trackButtonClick({
      name: `${dialogName}-dialog-campaign-group-selection`,
      value: selection,
    })
    setCampaignTypeSelection(selection)
  }

  const onSelectCampaignType = (campaignType: CampaignType) => {
    trackButtonClick({
      name: `${dialogName}-dialog-campaign-type-selection`,

      value: campaignType,
    })
    redirectToAddCampaignPage(campaignType)
  }

  const dialogTitle = useMemo(() => {
    switch (campaignTypeSelection) {
      case 'cart':
        return t('campaigns:campaign_type_selection.cart_title')
      case 'product':
        return t('campaigns:campaign_type_selection.product_title')
      case 'pricelist':
        return t('campaigns:campaign_type_selection.pricelist_title')
      case 'package':
        return t('campaigns:campaign_type_selection.package_title')
      default:
        return t('campaigns:campaign_type_selection.root_title')
    }
  }, [campaignTypeSelection, t])

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    if (props.onClose) {
      props.onClose()
    }
  }

  useEffect(() => {
    if (props.open) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, trackDialogOpen])

  return (
    <Dialog
      onClose={onClose}
      open={props.open}
      maxWidth="sm"
      fullWidth
      data-testid="add-campaign-dialog"
    >
      <DialogTitle type="extended" data-testid="campaign-dialog-title">
        {campaignTypeSelection !== undefined && (
          <ChevronLeftIcon
            fontSize="medium"
            sx={{ cursor: 'pointer' }}
            onClick={() => setCampaignTypeSelection(undefined)}
            data-testid="navigate-back-icon"
          />
        )}
        {dialogTitle}
      </DialogTitle>
      <DialogContent sx={{ p: 0 }}>
        {campaignTypeSelection === undefined && (
          <CampaignTypeSelection
            onCampaignTypeGroupSelection={onCampaignTypeGroupSelection}
            data-testid="root-campaign-type-selection-container"
          />
        )}

        {campaignTypeSelection === 'cart' && (
          <CartCampaignSelection
            onSelectCampaignType={onSelectCampaignType}
            data-testid="cart-campaign-type-selection-container"
          />
        )}

        {campaignTypeSelection === 'product' && (
          <ProductCampaignSelection
            onSelectCampaignType={onSelectCampaignType}
            data-testid="product-campaign-type-selection-container"
          />
        )}

        {campaignTypeSelection === 'package' && (
          <PackageCampaignSelection
            onSelectCampaignType={onSelectCampaignType}
            data-testid="package-campaign-type-selection-container"
          />
        )}

        {campaignTypeSelection === 'pricelist' && (
          <PricelistCampaignSelection
            onSelectCampaignType={onSelectCampaignType}
            data-testid="pricelist-campaign-type-selection-container"
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={trackButtonClickEvent(
            { name: `${dialogName}-dialog-cancel` },
            onClose,
          )}
          color="secondary"
          size="small"
          data-test-id="cancel-dialog-button"
        >
          {t('shared:action.cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
