import { useMutation } from '@apollo/client'
import { Menu, MenuItem } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ExportWarehousesDocument, FileType } from '../../../generated/graphql'
import { ExportDialog } from '../../../components/export-dialog'
import { useTracking } from '../../../hooks/tracking'
import { useState } from 'react'
import { useSnackbar } from 'notistack'
import { MoreButton } from '../../../components/more-button'

export const WarehousesContextMenu = () => {
  const { t } = useTranslation(['shared', 'settings'])
  const { trackButtonClickEvent } = useTracking()
  const [isExportDialogOpen, setExportDialogOpen] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const [exportWarehouses, { loading }] = useMutation(ExportWarehousesDocument)

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => setAnchorEl(null)

  const onExport = async (fileType: FileType) => {
    try {
      const { data } = await exportWarehouses({ variables: { fileType } })

      const fileLink = data?.exportWarehouses

      if (fileLink) {
        window.location.href = fileLink
      }

      enqueueSnackbar(t('settings:warehouse.export_success'), {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar(t('settings:warehouse.export_error'), {
        variant: 'error',
      })
    } finally {
      setExportDialogOpen(false)
    }
  }

  return (
    <>
      <MoreButton data-testid="more-options-button" onClick={handleClick} />

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        data-test-id="more-options-button"
        onClick={handleClose}
        onClose={handleClose}
      >
        <MenuItem
          data-testid="export-warehouses-option"
          onClick={
            (trackButtonClickEvent({
              name: 'export-warehouses',
            }),
            () => {
              setExportDialogOpen(true)
            })
          }
        >
          {t('settings:warehouse.export_warehouses')}
        </MenuItem>
      </Menu>

      <ExportDialog
        open={isExportDialogOpen}
        onClose={() => setExportDialogOpen(false)}
        label={t('settings:warehouse.export_warehouses')}
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSuccess={onExport}
        isLoading={loading}
      />
    </>
  )
}
